import React, { useEffect } from 'react'
import 'twin.macro'

import SEO from 'components/shared/seo'
import useVisitSanFrancisco from 'content-queries/mx/visit-san-francisco'
import fireEvent from 'context/tracking/events'
import { AutomaticPopup } from 'mx/automatic-popup'
import { TextDivider } from 'mx/components/text-divider'
import { Hero, MobileHero } from 'mx/homepage/hero'
import { Press } from 'mx/homepage/press'
import { Reviews } from 'mx/homepage/reviews'
import Layout from 'mx/layout'
import { BottomCta } from 'mx/locations/bottom-cta'
import { SectionContent } from 'mx/locations/section-content'
import { SectionContentFull } from 'mx/locations/section-content-full'

const VisitSanFranciscoPage = () => {
  useEffect(() => {
    fireEvent({
      type: 'page_viewed',
      pageTitle: 'Visit San Francisco Studio',
    })
  }, [])

  const { data } = useVisitSanFrancisco()
  const [
    {
      node: {
        sectionModules: [
          hero,
          perform,
          designed,
          engineered,
          inspiration,
          price,
          bottomCta,
        ],
      },
    },
  ] = data

  return (
    <Layout>
      <AutomaticPopup />
      <Hero data={hero.contentSlots?.[0]} />
      <MobileHero data={hero.contentSlots?.[0]} />
      <TextDivider title="As seen in" />
      <Press hideQuotes={true} />
      <SectionContent data={perform} />
      <SectionContent data={designed} />
      <Reviews />
      <SectionContent data={engineered} />
      <SectionContent data={inspiration} />
      <SectionContentFull data={price} />
      <BottomCta data={bottomCta} />
    </Layout>
  )
}
export default VisitSanFranciscoPage

export const Head = () => {
  const { data } = useVisitSanFrancisco()
  const [
    {
      node: { image },
    },
  ] = data

  return (
    <SEO
      description="Feel the quality of our European cabinetry and speak with our expert consultants at our San Francisco Studio."
      path="/visit-san-francisco"
      title="San Francisco Studio"
      image={
        image.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src
      }
    />
  )
}
